<!-- =========================================================================================
    File Name: Invoice.vue
    Description: Invoice Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div id="invoice-page" v-if="orderDetails.id">
    <div class="flex flex-wrap items-center justify-between">
      <div class="flex items-center">
        <vs-button
          class="mb-base mr-3"
          icon-pack="feather"
          icon="icon icon-file"
          @click="printInvoice"
          >طباعة
        </vs-button>
      </div>
    </div>

    <vx-card id="invoice-container">
      <!-- INVOICE METADATA -->
      <div class="vx-row leading-loose p-base">
        <div class="vx-col w-full md:full mt-base">
          <img
            src="@/assets/images/logo/logo.svg"
            class="m-auto"
            width="150"
            height="62.45"
            alt="vuexy-logo"
          />
        </div>
        <div class="vx-col w-full md:full mt-2">
          <div class="invoice__invoice-detail vx-row align-items-center">
            <div class="vx-col md:w-1/2">
              <h3>رقم الفاتورة.</h3>
              <p>{{ orderDetails.invoice.id }}</p>
            </div>
            <div class="dates vx-col md:w-1/2 text-right">
              <h6 class="mt-4">تاريخ الطلب</h6>
              <p>{{ orderDetails.created_at }}</p>
              <div v-if="orderDetails.delivery_date">
                <h6 class="mt-4">تاريخ التوصيل</h6>
                <p>{{ orderDetails.delivery_date }}</p>
              </div>
            </div>
          </div>
        </div>
        <vs-divider></vs-divider>
        <div class="flex w-full justify-between mx-5">
          <div class="vx-col w-full md:w-1/2">
            <h5>تم التسليم إلي السيد/ة</h5>
            <div class="invoice__recipient-info">
              <p>{{ recipientDetails.name }}</p>
              <p>
                {{
                  recipientDetails.address.find(
                    (v) => v.id == orderDetails.address_id
                  ).title
                }}
              </p>
              <p>
                {{
                  recipientDetails.address.find(
                    (v) => v.id == orderDetails.address_id
                  ).city.name
                }}
                - {{ recipientDetails.address[0].description }}
              </p>
            </div>
            <div class="invoice__recipient-contact">
              <p class="flex">
                <feather-icon
                  icon="PhoneIcon"
                  svgClasses="h-4 w-4"
                ></feather-icon>
                <span class="ml-2" dir="ltr">{{ recipientDetails.phone }}</span>
              </p>
            </div>
          </div>
          <div class="vx-col w-full md:w-1/2 mt-base text-right">
            <h5>بيانات المحل</h5>
            <div class="invoice__company-info">
              <p>{{ activeUserInfo.name }}</p>
              <p v-if="activeUserInfo.address.length">
                {{ activeUserInfo.address }}
              </p>
            </div>
            <div class="invoice__company-contact">
              <p class="flex justify-end">
                <feather-icon
                  icon="PhoneIcon"
                  svgClasses="h-4 w-4"
                ></feather-icon>
                <span class="ml-2 ltr">{{ activeUserInfo.phone }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>

      <!-- INVOICE CONTENT -->
      <div class="">
        <!-- INVOICE Items TABLE -->
        <vs-table hoverFlat :data="orderDetails.items">
          <!-- HEADER -->
          <template slot="thead">
            <vs-th>SKU المنتج</vs-th>
            <vs-th>الصورة</vs-th>
            <vs-th>إسم المنتج</vs-th>
            <vs-th>اللون</vs-th>
            <vs-th>الحجم</vs-th>
            <vs-th>السعر</vs-th>
            <vs-th>الكمية</vs-th>
            <vs-th>الإجمالي</vs-th>
          </template>

          <!-- DATA -->
          <template slot-scope="{ data }">
            <vs-tr v-for="(tr, index) in data" :key="index">
              <vs-td class="span" v-if="tr.product">{{ tr.product.sku }}</vs-td>
              <vs-td class="table-image-container flex items-center">
                <img
                  v-if="tr.product.first_media"
                  :src="tr.product.first_media.path"
                  class="table-image"
                />
              </vs-td>
              <vs-td class="span" v-if="tr.product">{{
                tr.product.title
              }}</vs-td>
              <vs-td class="span">{{ tr.quantity_details.color.name }}</vs-td>
              <vs-td class="span">{{ tr.quantity_details.size }}</vs-td>
              <vs-td class="span" v-if="tr.product.new_price"
                ><span style="text-decoration: line-through"
                  >{{ tr.product.price }}
                  {{
                    tr.product.currency.base == "LYD"
                      ? "د.ل"
                      : tr.product.currency.base
                  }}
                </span>

                ,{{ tr.product.new_price }}
                {{
                  tr.product.currency.base == "LYD"
                    ? "د.ل"
                    : tr.product.currency.base
                }}
              </vs-td>
              <vs-td class="span" v-else
                >{{ tr.product.price }}
                {{
                  tr.product.currency.base == "LYD"
                    ? "د.ل"
                    : tr.product.currency.base
                }}</vs-td
              >
              <vs-td class="span">{{ tr.quantity }} </vs-td>
              <vs-td class="span">{{
                tr.total_amount +
                " " +
                (tr.product.currency.base == "LYD"
                  ? "د.ل"
                  : tr.product.currency.base)
              }}</vs-td>
            </vs-tr>
          </template>
        </vs-table>

        <!-- INVOICE SUMMARY TABLE -->
        <vs-table hoverFlat class="w-1/2 ml-auto mt-4" data="invoiceData">
          <vs-tr>
            <vs-th>المجموع</vs-th>
            <vs-td>{{ orderDetails.total_amount + products_discount }} د.ل</vs-td>
          </vs-tr>

          <!-- <vs-tr>
            <vs-th>تفاصيل الاجمالي</vs-th>
            <vs-td>
              (
              <span
                style="width: fit-content"
                class="mx-1"
                v-for="(value, currency) in orderDetails.invoice
                  .rest_amount_details"
                :key="currency"
              >
                {{ value + " " + (currency == "LYD" ? "د.ل" : currency) }}
                , </span
              >)
            </vs-td>
          </vs-tr> -->

          <vs-tr>
            <vs-th>التخفيض</vs-th>
            <vs-td>{{ orderDetails.invoice.discount }} د.ل</vs-td>
          </vs-tr>
          <vs-tr v-if="orderDetails.invoice.coupon">
            <vs-th> الكوبون المستخدم</vs-th>
            <td>
              {{ orderDetails.invoice.coupon.code }} |
              {{ orderDetails.invoice.coupon.value }} د.ل
            </td>
          </vs-tr>
          <vs-tr>
            <vs-th>الكلي</vs-th>
            <td>{{ orderDetails.total_amount }} د.ل</td>
          </vs-tr>
         
          <vs-tr>
            <vs-th>وسيلة الدفع</vs-th>
            <td>
              {{ orderDetails.invoice.payment_method.name }}
            </td>
          </vs-tr>
        </vs-table>
      </div>
    </vx-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      recipientDetails: {},
      invoiceDetails: {
        invoiceNo: "",
        invoiceDate: "this.tr.createdAt",
      },
      products_discount: 0,
      orderDetails: {
        items: [],
      },
      order_id: "",
    };
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
  },
  methods: {
    ...mapActions("orders", ["fetchOrder"]),
    printInvoice() {
      window.print();
    },
    getOrderInvoice() {
      this.fetchOrder(this.order_id).then((response) => {
        this.recipientDetails = response.data.data.user;
        this.orderDetails = response.data.data;
        this.orderDetails.items.forEach((item) => {
          if (item.product.new_price) {
            this.products_discount +=
              item.product.price - item.product.new_price;
          }
        });
      });
    },
  },
  components: {},
  created() {
    localStorage.setItem("order_id", this.$route.params.id);
  },
  mounted() {
    this.$emit("setAppClasses", "invoice-page");
    this.order_id = localStorage.getItem("order_id");
    if (this.order_id) this.getOrderInvoice();
  },
};
</script>

<style lang="scss">
.span {
  font-size: 10px !important;
}
@page {
  size: auto;
  /* auto is the initial value */
  margin: 0;
  /* this affects the margin in the printer settings */
}

@media print {
  .invoice-page {
    * {
      visibility: hidden;
    }

    #content-area {
      margin: 0 !important;
    }

    #invoice-container,
    #invoice-container * {
      visibility: visible;
    }

    #invoice-container {
      position: absolute;
      left: 0;
      top: 0;
      box-shadow: none;

      .dates {
        position: absolute;
        right: 0;
      }
    }
  }
}
.table-image-container {
  text-align: center;
  height: 110px;
  // background: #fff;
  span {
    display: flex;
    justify-content: flex-start;
  }
  .table-image {
    height: 80px;
  }
}
</style>
